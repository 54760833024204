import Work from './pages/work/work';
import About from './pages/about/about';
import CopilotRichArtifacts from './pages/copilot-rich-artifacts/copilot-rich-artifacts';
import CopilotRichArtifactsTest from './pages/copilot-rich-artifacts/rich-artifacts-test';
import LoopCodeBlocks from './pages/loop-code-blocks/loop-code-blocks';
import LoopIntegration from './pages/loop-integration/loop-integration';
import LoopPaywall from './pages/loop-paywall/loop-paywall';
import MorganStanley from './pages/morgan-stanley/morgan-stanley';
import Samana from './pages/samana/samana';
import GoXLR from './pages/go-xlr/go-xlr';
import GoStudioFX from './pages/go-studio-fx/go-studio-fx';
import Blender from './pages/blender/blender';
import Teamwrk from './pages/teamwrk/teamwrk';
import {BrowserRouter as Router, Route, Routes, Navigate} from "react-router-dom"
import Footer from './components/footer/footer';
import NavBar from './components/nav-bar/nav-bar';
import React from 'react';
import ScrollToTop from './components/scroll-to-top/scroll-to-top';
function App() {

  return (
    <Router>
            <NavBar />
            <ScrollToTop/>
            <Routes>
                <Route path="/" element={<Work/>} />
                <Route path="/about" element={<About/>} />
                <Route path="/copilot-rich-artifacts" element={<CopilotRichArtifacts/>} />
                <Route path="/loop-code-blocks" element={<LoopCodeBlocks/>} />
                <Route path="/loop-integration" element={<LoopIntegration/>} />
                <Route path="/loop-paywall" element={<LoopPaywall/>} />
                <Route path="/morgan-stanley" element={<MorganStanley/>} />
                <Route path="/samana" element={<Samana/>} />
                <Route path="/go-xlr" element={<GoXLR/>} />
                <Route path="/go-studio-fx" element={<GoStudioFX/>} />
                <Route path="/blender" element={<Blender/>} />
                <Route path="/teamwrk" element={<Teamwrk/>} />
                <Route path="*"element={<Navigate to="/" replace />} />
            </Routes>
            <Footer />

    </Router>
  )
}

export default App;

