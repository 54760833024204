import React from "react";
import { Link, ScrollRestoration } from 'react-router-dom';
import './loop-code-blocks.scss';
import LoopPaywall from "../../assets/images/loop-paywall/loop-paywall-cover.png"
import Cover from "../../assets/images/loop-code-blocks/loop-code-blocks-cover.png"
import Context from "../../assets/images/loop-code-blocks/loop-code-blocks-context.gif"
import LineCount from "../../assets/images/loop-code-blocks/line-count.png"
import Prototype from "../../assets/images/loop-code-blocks/prototype.gif"
import Feedback from "../../assets/images/loop-code-blocks/user-feedback.png"
import PrototypeBG from "../../assets/images/loop-code-blocks/prototype-bg.png"
import CodingLanguages from "../../assets/images/loop-code-blocks/coding-languages.png"
import CopyCode from "../../assets/images/loop-code-blocks/copy-code.png"
import Mermaid from "../../assets/images/loop-code-blocks/mermaid.png"
import Attribution from "../../assets/images/loop-code-blocks/attribution.png"
import Theme from "../../assets/images/loop-code-blocks/theme.png"
import Notion from "../../assets/images/loop-code-blocks/notion.png"
import Github from "../../assets/images/loop-code-blocks/github.png"
import A11y from "../../assets/images/loop-code-blocks/a11y.png"
import Specs from "../../assets/images/loop-code-blocks/design-specs.png"
import Telemetry from "../../assets/images/loop-code-blocks/telemetry.png"
import ArrowRight from "../../assets/icons/arrow-right.svg"

const LoopPaywallProject = () => {
  return <div className="page">

    <div className="intro">
      <div>
        <div id="heading">
          <h1 style={{marginBottom: "16px"}}>Loop code blocks</h1>
          <h5>Poduct designer / Feb 2023 - May 2023</h5>
        </div>
        </div>
          <img className="img" src={Cover} alt="Loop code blocks" />
          <div id="paragraph">
            <p><span style={{fontWeight: "600"}}>Code Blocks</span> is a feature designed to meet one of the most highly requested user needs in Microsoft Loop—enabling seamless integration of code snippets directly into workspaces, pages, notes, project wikis, and documentation. It includes essential functionalities like syntax highlighting, code folding, and line wrapping, making it indispensable for improved readability and collaboration.
            This feature was introduced at the <Link className="link" to="https://build.microsoft.com/en-US/home" target='_blank'>Microsoft Build</Link> 2023 conference and made publicly available in <span style={{fontWeight: "600"}}>November 2023.</span></p>
          </div>
        </div>


        <div className="key-features">
          <h3>Key Features</h3>
          <div id="feature 1" className="features-grid-odd">
            <div className="features-grid-text-odd">
              <h6>Multi-language Support</h6>
              <p>Select from a comprehensive list of programming languages for syntax highlighting, enabling users to work in their preferred coding environment.</p>
            </div>
            <img className="features-img" src={CodingLanguages}/>
          </div>
          <div id="feature 2" className="features-grid-even">
            <img className="features-img" src={Attribution}/>
            <div className="features-grid-text-even">
              <h6>Collaboration-First Design</h6>
              <p>Track edits with attribution and share code blocks as reusable Loop components.</p>
            </div>
          </div>
          <div id="feature 3" className="features-grid-odd">
            <div className="features-grid-text-odd">
              <h6>Interactive Options</h6>
              <p>One-click code copying for streamlined workflows, toggleable line numbers for enhanced customization, and jump to line for easy navigation.</p>
            </div>
            <img className="features-img" src={CopyCode}/>
          </div>
          <div id="feature 4" className="features-grid-even">
            <img className="features-img" src={Theme}/>
            <div className="features-grid-text-even">
              <h6>Accessibility & Theming</h6>
              <p>Comprehensive support for dark, light, and high-contrast themes with optimized keyboard navigation.</p>
            </div>  
          </div>
          <div id="feature 5" className="features-grid-odd">
            <div className="features-grid-text-odd">
              <h6>Advanced Visualization</h6>
              <p>Support for diagramming languages like Mermaid.</p>
            </div>
            <img className="features-img" src={Mermaid}/>
          </div>
        </div>


      <div id="context" className="even-grid">
        <div>
          <h3 style={{padding: "12px 0"}}>Context</h3>
        </div>
        <div className="even-grid">
          <div className="text-paragraph">
            <h6>Problem statement</h6>
            <p>Microsoft Loop users lacked a robust solution to integrate and collaborate on code snippets, creating an adoption barrier for technical and cross-functional teams.</p>
            </div>
        </div>
        <div className="even-grid">
          <div className="text-paragraph">
            <h6>Competitive Landscape</h6>
          </div>
          <div className="column-grid">
                <div>
                  <img className="img" src={Notion} style={{borderRadius: "0", paddingBottom: "12px"}} />
                  <p>Code blocks in Notion</p>
                </div>
                <div>
                  <img className="img" src={Github} style={{borderRadius: "0", paddingBottom: "12px"}} />
                  <p>Github code snippets</p>
                </div>
              </div>
        </div>
        <div className="even-grid">
          <div className="text-paragraph">
            <h6>Feedback-Driven Approach</h6>
            <ul>      
              <p>
                <li>
                  Internal demand: Teams like Clipchamp requested this feature for engineering documentation workflows.
                </li>
                <li style={{paddingBottom: "0"}}>
                  User studies highlighted the necessity of syntax highlighting and frictionless sharing.
                </li>
              </p>
            </ul>
          </div>
          {/* <img className="img" style={{maxWidth: "600px"}} src={Feedback}/> */}
        </div>
      </div>


      <div id="context" className="even-grid">
        <div>
          <h3 style={{padding: "12px 0"}}>My role</h3>
        </div>
        <div className="even-grid">
          <div className="text-paragraph">
            <h6>Title</h6>
            <p>Lead Mobile Designer</p>
            </div>
        </div>
        <div className="even-grid">
          <div className="text-paragraph">
            <h6>Team structure</h6>
            <ul>      
              <p>
                <li>
                  <span style={{fontWeight: "600"}}>2 Designers:</span> Focused on web and mobile experience.
                </li>
                <li>
                  <span style={{fontWeight: "600"}}>1 Product Manager:</span> Guided feature scope and requirements.
                </li>
                <li style={{paddingBottom: "0"}}>
                  <span style={{fontWeight: "600"}}>2 Engineers & 1 Engineering Manager:</span> Executed technical implementation.
                </li>
              </p>
            </ul>
          </div>
        </div>
        <div className="even-grid">
          <div className="text-paragraph">
            <h6>Core Responsibilities</h6>
            <ul>      
              <p>
                <li>
                  <span style={{fontWeight: "600"}}>Conceptual Leadership:</span> Co-led the ideation and definition of code block functionality based on user feedback and competitive analysis.
                </li>
                <li>
                  <span style={{fontWeight: "600"}}>Mobile-Specific Expertise:</span> Designed mobile layouts with a focus on parity and usability, ensuring consistent user experiences across platforms.
                </li>
                <li style={{paddingBottom: "0"}}>
                  <span style={{fontWeight: "600"}}>Prototyping & Testing:</span> Delivered high-fidelity prototypes for user testing and stakeholder alignment, refining designs iteratively based on feedback.
                </li>
              </p>
            </ul>
          </div>
          {/* <img className="img" src={Timeline}/> */}
        </div>
      </div>

      <div id="design process" className="even-grid">
        <div className="text-paragraph">
          <h3 style={{padding: "12px 0"}}>Design process</h3>
          <p>We began the project by conducting initial user testing, analyzing competitor offerings, and incorporating customer feedback to identify key requirements. Collaborating closely with our PMs, we refined these requirements to address user pain points and ensure alignment with Loop’s broader goals. The finalized requirements included:</p>
        </div>
        <div className="even-grid">
          <div className="text-paragraph">
            <ul>      
              <p>
                <li>
                  <span style={{fontWeight: "600"}}>Code Block Creation:</span> Enable users to insert code blocks through the "/" menu or markdown commands.
                </li>
                <li>
                  <span style={{fontWeight: "600"}}>Easy Copying:</span> Provide a single-click copy button for effortless code sharing.
                </li>
                <li>
                  <span style={{fontWeight: "600"}}>Text Importing:</span> Allow users to copy and paste text into the code block directly from other sources within the Loop canvas.
                </li>
                <li>
                  <span style={{fontWeight: "600"}}>Syntax Highlighting:</span>
                  <ul>
                    <p>
                      <li>
                        Users can select programming languages from a comprehensive list aligned with VS Code for a consistent Microsoft ecosystem experience.
                      </li>
                      <li>
                        Code syntax highlights dynamically based on the selected language.
                      </li>
                      <li>
                        Default language is Plain Text, with subsequent code blocks defaulting to the user’s last selected language.
                      </li>
                    </p>
                  </ul>
                </li>
                <li>
                  <span style={{fontWeight: "600"}}>Line Numbers:</span> Users can toggle line numbers for better readability and customization.
                </li>
                <li style={{paddingBottom: "0"}}>
                  <span style={{fontWeight: "600"}}>Seamless Integration:</span> Ensure code blocks follow standard block functionality within the Loop canvas.
                </li>
              </p>
            </ul>
          </div>
            <p>The finalized requirements were translated into detailed design specifications for both web and mobile platforms, ensuring accessibility considerations such as keyboard navigation, theming consistency, and focus state improvements were integrated from the outset.</p>
            <img className="img" src={Specs}/>
        </div>
      </div>

      <div id="prototype" className="even-grid">
        <div className="text-paragraph">
          <h3 style={{padding: "12px 0"}}>Prototype Testing & User Insights</h3>
          <p>We conducted targeted usability testing with five representative users (4 developers and 1 program manager) to evaluate the prototype and concept we designed. These participants regularly collaborated across technical and non-technical teams using tools like Google Suite, Slack, Jira, GitHub, and BitBucket.</p>
          {/* <img className="img" src={PrototypeBG}/> */}
        </div>
        <div className="even-grid">
          <div className="text-paragraph">
            <h6>Key Findings & Actions</h6>
            <ul>      
              <p>
                <li>
                  <span style={{fontWeight: "600"}}>Toggle Line Numbers:</span> Participants appreciated the ability to toggle line numbers, which informed our prioritization of this feature for enhanced clarity.
                </li>
                <li>
                  <span style={{fontWeight: "600"}}>Small Code Snippets:</span> Users predominantly worked with snippets of ~30 lines or less, leading us to optimize the design for documentation use cases.
                </li>
                <li>
                  <span style={{fontWeight: "600"}}>Mobile Parity:</span> Feedback underscored the importance of consistent experiences between mobile and web platforms, guiding our responsive design efforts.
                </li>
                <li style={{paddingBottom: "0"}}>
                  <span style={{fontWeight: "600"}}>Future Feature Opportunities</span>  Line-specific commenting was highlighted as a valuable enhancement for collaboration, earmarked for future iterations.
                </li>
              </p>
            </ul>
          </div>
        </div>
      </div>

      <div id="accessibility enhancements" className="even-grid">
        <div className="text-paragraph">
          <h3 style={{padding: "12px 0"}}>Accessibility Enhancements</h3>
          <p>We tested markdown and code blocks with Loop accessibility experts. These were their recommendations:</p>
        </div>
        <div className="key-features">
          <div id="feature 5" className="features-grid-odd">
            <div className="features-grid-text-odd">
              <ul>      
              <p>
                <li>
                  Seamless keyboard navigation for top options.
                </li>
                <li>
                  Integration of VS Code color tokens for consistent theming.
                </li>
                <li>
                  Addition of "Go to line" for improved line navigation.
                </li>
                <li style={{paddingBottom: "0"}}>
                  Clear focus state outlines for better visibility and interaction feedback.
                </li>
              </p>
            </ul>
            </div>
            <img className="features-img" src={A11y}/>
          </div>
          </div>
      </div>

      <div id="telemetry & metrics" className="even-grid">
        <div>
          <h3 style={{padding: "12px 0"}}>Telemetry & metrics</h3>
          <img className="img" src={Telemetry}/>
        </div>
      </div>

      <div id="impact & outcomes" className="even-grid">
        <div>
          <h3 style={{padding: "12px 0"}}>Impact & outcomes</h3>
        </div>
        <div className="even-grid">
          <div className="text-paragraph">
          <ul>      
              <p>
                <li>
                  Launched a highly anticipated feature addressing a critical user need, boosting Loop’s adoption.
                </li>
                <li>
                  Positioned Microsoft Loop as a competitive alternative to Notion and GitHub for technical and non-technical teams.
                </li>
                <li style={{paddingBottom: "0"}}>
                  Enhanced user collaboration through thoughtful design and innovative functionality.
                </li>
              </p>
            </ul>
          </div>
        </div>
      </div>

      <div id="lessons learned" className="even-grid">
        <div>
          <h3 style={{padding: "12px 0"}}>Lessons learned & Next steps</h3>
        </div>
        <div className="even-grid">
          <div className="text-paragraph">
            <h6>Successes</h6>
            <ul>      
                <p>
                  <li>
                    Early engagement with users and robust telemetry ensured alignment with real-world needs.
                  </li>
                  <li style={{paddingBottom: "0"}}>
                    Streamlined prioritization processes allowed impactful delivery despite resource constraints.
                  </li>
                </p>
              </ul>
            </div>
            <div className="text-paragraph">
            <h6>Challenges</h6>
            <ul>      
                <p>
                  <li style={{paddingBottom: "0"}}>
                    Balancing scope and feature parity across platforms while staying resource-efficient.
                  </li>
                </p>
              </ul>
            </div>
            <div className="text-paragraph">
            <h6>Future opportunities</h6>
            <ul>      
                <p>
                  <li>
                    Integrate line-specific commenting for improved collaboration.
                  </li>
                  <li style={{paddingBottom: "0"}}>
                    Expand support for additional programming languages and customizations.
                  </li>
                </p>
              </ul>
            </div>
          </div>
        </div>


      <div className="next">
          <h3 style={{padding: "12px 0", marginBottom: "24px"}}>Up next</h3>
          <div className="next-grid">
            <img className="img" src={LoopPaywall} alt="Loop Paywall" />
            <div>
              <h6>Loop Paywall</h6>
              <Link className="explore" to="/loop-paywall">Explore<img src={ArrowRight} alt="Arrow right" style={{width: "24px", marginLeft: "8px"}} /></Link>
            </div>
          </div>
      </div>
  </div>;
};

export default LoopPaywallProject