import React from "react";
import './about.scss';
import Headshot from "../../assets/images/headshot.jpg";
import { Link } from 'react-router-dom';

const About = () => {
  return <div>
    <div className="about">
      <div className="image-mask-rect">
        <img className="image" src={Headshot} alt="Headshot of Ravin Perera"/>
        </div>
        <div>
          <h1 className="heading">Hi, I'm Ravin.</h1>
          <div className="text-paragraph">
            <p>A Sri Lankan Canadian product designer currently based in Nanaimo, BC. Growing up between two vastly different cultures, I have anchored my practice at the intersection between technology and culture. I am passionate about design that considers the impact on culture and iterates for better human interactions in society.</p>
            <p>7 years into my product design career, I have worked in various industries such as healthcare, CRM, audio, fintech, and recently in Gen AI. My background in industrial design helps me approach problems through a user-centered design approach while balancing business goals.
              I currently work as a Product Designer on the <Link className="link" to="https://www.microsoft.com/en-us/microsoft-loop" target="_blank">Loop team at Microsoft</Link>.</p>
            <p>At Microsoft, I am currently one of the lead designers on <Link className="link" to="/copilot-rich-artifacts" target="_blank">rich artifacts on Copilot Pages</Link>, and lead the design efforts for notifications across Loop experiences.</p>
            <p>In January of 2021, I proudly launched my passion project <Link className="link" to="https://www.samana.lk/support-list" target="_blank">Samana</Link>, which is a centralized list of support services available in Sri Lanka.</p>
            <p>Aside from design, here are a few things I enjoy:</p>
                <ul>
                  <p>
                    <li>
                    Traveling to new places and experiencing new cultures.
                    </li>
                    <li>
                    Trying out and cooking food.
                    </li>
                    <li>
                    Taking travel photos and photos of food.
                    </li>
                    <li>
                    Playing Badminton.
                    </li>
                    <li>
                    Playing video games.
                    </li>
                    <li>
                    Petting dogs and cats.
                    </li>
                    <li>
                    Producing digital music on Ableton.
                    </li>
                  </p>
                </ul>
          </div>
        </div>
      </div>
    </div>
};

export default About